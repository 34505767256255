import React, { FC } from 'react';
import { Link } from 'gatsby';
import * as styles from './Breadcrumb.module.styl';

interface BreadcrumbItem {
  useGatsbyLink?: boolean
  to?: string
  label?: string
}

interface Props {
  items: Array<BreadcrumbItem>
}

const Breadcrumb: FC<Props> = ({ items }) => {
  const generateBreadcrumbItem = (item: BreadcrumbItem, isLastItem = false): React.ReactElement => {
    const useGatsbyLink = item.useGatsbyLink ?? true;
    if (!item.to) {
      return <span className={isLastItem ? 'has-text-weight-semibold' : ''}>{item.label}</span>;
    }
    if (useGatsbyLink) {
      return (
        <Link to={item.to} className={`has-text-grey-dark ${isLastItem ? 'has-text-weight-semibold' : ''}`}>
          {item.label}
        </Link>
      );
    }
    return (
      <a href={item.to} className={`has-text-grey-dark ${isLastItem ? 'has-text-weight-semibold' : ''}`}>
        {item.label}
      </a>
    );
  };

  return (
    <ol className={`is-flex is-size-7 ${styles.bread} ${styles.breadcrumbList} ${styles.isBorderBottomLineBlackTer}`}>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <li className={`is-flex has_vertical_centered ${styles.whiteSpaceNowrap}`} key={`breadcrumb-${item.label}`}>
            {generateBreadcrumbItem(item, isLastItem)}
          </li>
        );
      })}
    </ol>
  );
};

export default Breadcrumb;
