import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * サーバーから返却される現在時刻を取得
 */
export const safeNow = async (): Promise<Dayjs> => {
  // Dateヘッダーから現在時刻取得
  const now = (await (axios.get('/now'))).headers.date ?? '';
  return dayjs(now);
};

/**
 * 日本時刻取得
 * @param date 日時
 */
export const jst = (date?: dayjs.ConfigType): Dayjs => dayjs(date).utcOffset(9);

/**
 * 元号取得
 * @param year: number 西暦
 */
export const getJapaneseEra = (year: number, month?: number, day?: number): string => {
  let era = '';
  if (year >= 1926 && year < 1989) {
    era = `(昭和${year - 1925}年)`;
  } else if (year >= 1989 && year < 2019) {
    if (month === 1 && (day ? (day <= 7) : false)) {
      era = `(昭和${year - 1925}年)`;
    } else {
      era = `(平成${year - 1988}年)`;
    }
  } else if (year >= 2019) {
    if (month ? (month <= 4) : false) {
      era = `(平成${year - 1988}年)`;
    } else {
      era = `(令和${year - 2018}年)`;
    }
  }
  return era;
};
