import React, { FC } from 'react';
import SEOBase from '@/newcar/ui/common/organism/SEO';

const SEO: FC = () => (
  <SEOBase
    title="カーリース・マイカーリースのお知らせ一覧 おトクにマイカー 定額カルモくん"
    description="おトクなお知らせ情報ページです。【ネット割最大49,500円】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心です。メンテナンスも月額定額にできるプランや、契約満了後に車がもらえるオプションもご用意。"
    ogTitle="お知らせ一覧 おトクにマイカー 定額カルモくん"
    ogDescription="おトクなお知らせ情報ページです。【ネット割最大49,500円】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心です。メンテナンスも月額定額にできるプランや、契約満了後に車がもらえるオプションもご用意。"
    ogUrl={`${process.env.GATSBY_SITE_URL}/information/`}
    twitterTitle="テレビで話題の安心マイカーリース - おトクにマイカー 定額カルモくん"
    twitterDescription="おトクなお知らせ情報ページです。【ネット割最大49,500円】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心です。メンテナンスも月額定額にできるプランや、契約満了後に車がもらえるオプションもご用意。"
  />
);

export default SEO;
