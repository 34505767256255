import React, { FC } from 'react';
import SEOBase, { Props } from '@/common/ui/organism/SEO';

const OG_IMAGE = 'https://carmo-kun.jp/img/ogimage/new_car_ogimage.jpg';

const SEO: FC<Props> = (props) => (
  <SEOBase
    {...props}
    ogImage={OG_IMAGE}
    twitterImage={OG_IMAGE}
  />
);

export default SEO;
