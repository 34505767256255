import { useStaticQuery, graphql } from 'gatsby';

const useAllNewCarInformationQuery = (): GatsbyTypes.allNewCarInformationQuery => useStaticQuery<GatsbyTypes.allNewCarInformationQuery>(graphql`
  query allNewCarInformation {
    allMicrocmsNewcarInformation {
      edges {
        node {
          id
          title
          url
          date
        }
      }
    }
  }
`);

export default useAllNewCarInformationQuery;
